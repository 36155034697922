import { useEffect } from "react";

import { addToDataLayer } from "@/utils/tracking.ts";

export const GoogleTagManagerClickId = () => {
  useEffect(() => {
    const passClickId = (event: MouseEvent) => {
      const target = (event.target as HTMLElement)?.closest("a[id]") as
        | HTMLAnchorElement
        | undefined;

      if (!target) {
        return;
      }

      const id = target.id;

      addToDataLayer({
        event: id,
      });
    };

    document.body.addEventListener("click", passClickId);

    return () => document.body.removeEventListener("click", passClickId);
  }, []);

  return null;
};
